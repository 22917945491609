
import {defineComponent, ref, onMounted, computed} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Spinner from '@/components/Spinner.vue';
import MasteringSheet from "@/services/MasteringSheet";
import BaseIcon from '@/components/ui/BaseIcon.vue';
import router from "@/router";
import {ConvertDate} from "@/components/converters/date";
import routerService from '@/services/routerService';

export default defineComponent({
  name: 'ArticlesDevelopmentSheet',
  components: {
    Spinner,
    BaseIcon
  },
  setup() {
    const route = useRoute();
    const router = useRouter()
    const tableHeader = ['Список артикулов (темповые)', 'Актуальный артикул', 'Дата изменения артикула'];
    const pageable = ref({page: 0, size: 20});
    const articles = ref({} as any);
    const isLoading = ref(false);
    const articlesContent = ref();

    const convertDate = computed(() => (date: any) => ConvertDate(date))

    async function previous() {
      if (pageable.value.page) {
        isLoading.value = true;
        pageable.value.page = --pageable.value.page;

        try {
          articles.value = await MasteringSheet.getSheetResult(route.params.id, pageable.value);
          isLoading.value = false;
          await routerService.setQuery('page', pageable.value.page, route.query, router)
          // scrollToTop()
        } catch (error) {
          pageable.value.page = ++pageable.value.page;
          isLoading.value = false
        }
      }
    }

    function scrollToBottom() {
      console.log('Scroll')
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 50)
    }

    async function next() {
      if (pageable.value.page + 1 !== articles.value.totalPages) {
        isLoading.value = true;

        pageable.value = {
          size: 20,
          page: ++pageable.value.page
        }

        try {
          articles.value = await MasteringSheet.getSheetResult(route.params.id, pageable.value);
          articlesContent.value = [...articlesContent.value, ...articles.value.content]
          await routerService.setQuery('page', pageable.value.page, route.query, router)
          scrollToBottom()
          isLoading.value = false;
        } catch (error) {
          pageable.value.page = --pageable.value.page;
          isLoading.value = false
        }
      }
    }

    function scrollToTop() {
      window.scrollTo({top: 0, behavior: 'smooth'})
    }

    async function getArticles(page: any) {
      try {
        isLoading.value = true;
        pageable.value = {
          page,
          size: 20 * (page + 1) // если у нас есть query=page тогда умножаем кол-во элементов на страницу текущую страницу (page + 1) => отсчет страницы идет с 0
        }
        pageable.value.page = 0;
        articles.value = {};
        articles.value = await MasteringSheet.getSheetResult(route.params.id, pageable.value);
        articlesContent.value = articles.value.content;
        isLoading.value = false;
      } catch (error) {
        isLoading.value = false;
        throw error
      }
    }

    async function goToArticle(item: any) {
      await routerService.setQuery('article', item.article, route.query, router)
      await router.push({name: 'article', params: {id: item.articleId}})
    }

    function goToEditList(item: any) {
      router.push(`/sheet/new/${route.params.id}`)
    }

    onMounted(async () => {
      isLoading.value = true;
      let loggedUser = localStorage.getItem('loggedUser');
      let cid = localStorage.getItem('cid');

      if (loggedUser && cid) {
        if (!route.query.page) {
          await routerService.setQuery('page', 0, route.query, router)
        }

        await getArticles(Number(route.query.page) || 0)
      } else {
        await router.push('/login')
      }
    });

    return {articlesContent, tableHeader, pageable, articles, isLoading, previous, next, goToArticle, goToEditList, convertDate}
  }
})
